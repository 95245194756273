.toggle {
    width: 400px;
    height: 70px;
    border: 2px solid #acacac;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;



    
}

.inner_toggle {
    border-radius: 5px;
    width: 45%;
    transition-duration: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 90%;
}

.on {
    background-color: #579d36;
    font-weight: bold;
    color: white;
}

.off {
    background-color: #7c7c7c;
    font-weight: bold;
    color: #c5c5c5;
}