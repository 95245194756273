@import "../../assets/scss/setup/setup.scss";

.Row {
  display: flex;
  padding: 10px;

  @include medium-size-max {
    flex-direction: column;
  }
}

.Col {
  flex: 1;
  padding: 10px;

  &:first-child {
    max-width: 200px;
  }

  @include medium-size-max {
    &:first-child {
      max-width: auto;
    }

    &:empty {
      padding: 0;
    }
  }
}

.Heading {
  position: relative;
  text-transform: capitalize;
  &.accepted {
    background: $c-lime;
    &::before {
      background: $c-lime;
    }
    @include medium-size-max {
      &::after {
        background: $c-lime;
      }
    }    
  }
  
  &.failure {
    background: $c-error-bg;
    &::before {
      background: $c-error-bg;
    }
    @include medium-size-max {
      &::after {
        background: $c-error-bg;
      }
    } 
  }
  
  &.cancel {
    background: $c-orange;
    &::before {
      background: $c-error-bg;
    }
    @include medium-size-max {
      &::after {
        background: $c-error-bg;
      }
    } 
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
  }

  @include medium-size-max {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
    }
  }
}




.Img {
  width: 118px;
  height: auto;
}

.Text {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: $c-black;

  b {
    font-weight: 900;
  }
}

.Copy {
  &__icon {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
    color: $c-green;

    svg {
      pointer-events: none;
    }
  }
}

.History {
  &__row {
    display: flex;
    position: relative;
    padding-bottom: 70px;

    &:after {
      content: "";
      width: 1px;
      height: calc(100% - 54px);
      background: $c-icon;
      position: absolute;
      top: 40px;
      left: 13px;
    }

    &:last-child {
      padding-bottom: 0;

      &:after {
        content: none;
      }
    }
  }

  &__icon {
    color: $c-icon;
    width: 26px;
    height: 26px;
  }

  &__info {
    margin-left: 30px;

    p {
      font-family: $f-family;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: $c-black;

      b {
        font-weight: 800;
      }
    }
  }

  &__link {
    font-weight: 500;
    color: $c-link-blue-l;
    text-decoration: underline;
    cursor: pointer;
  }
}

.Modal {
  &__body {
    max-width: 900px;
    display: flex;
    margin: 15px 0;

    @include small-size-max {
      flex-direction: column;
    }
  }

  &__left {
    margin-right: 50px;

    @include small-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    color: $c-icon;
    width: 26px;
    height: auto;
    margin-right: 13px;
    padding-top: 3px;

    svg {
      width: inherit;
      height: auto;
    }
  }

  &__info {
    h2 {
      font-family: $f-family;
      font-weight: 900;
      font-size: 24px;
      line-height: 33px;
      color: $c-black;
    }

    p {
      font-family: $f-family;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: $c-black;
    }
  }
}

.Notes {
  &__message {
    font-weight: 300;
  }

  &__note {
    font-weight: 300;
    margin: 15px 0;

    &:last-child, > *:last-child {
      margin-bottom: 0;
    }

    &__time {
      font-size: 13px;
      margin-bottom: 3px;
    }

    &__content {
      font-size: 15px;
      background-color: $c-white-50;
      padding: 10px 12px;
      border-radius: 5px;
      line-height: 1.3em;
    }
  }

  &__submit {
    position: absolute;
    bottom: 22px;
    right: 10px;
    cursor: pointer;

    @media screen and (max-width: 365px) {
      bottom: 30px;
      right: 5px;
    }
  }

  &__form {
    position: relative;
    margin-top: 30px;

    textarea {
      resize: none;
      border: none;
      width: 100%;
      padding: 10px 55px 10px 20px;

      @media screen and (max-width: 365px) {
        height: calc(4em + 20px);
      }
      @media screen and (max-width: 380px) {
        padding-left: 10px;
        padding-right: 50px;
      }
  
      &::placeholder {
        white-space: pre-line;
        position: relative;
        top: 5px;
        max-width: 260px;
        color: $c-gray-2;
        font-style: italic;
        font-size: 15px;
        line-height: 18px;
        font-weight: 300;
      }
    }
  }
}