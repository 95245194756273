@import "../../assets/scss/setup/setup.scss";

.Heading {
  background: $c-lime;
  padding: 30px 0;

  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include small-size-max {
      flex-direction: column;
      text-align: center;
    }
  }

  &__title {
    will-change: transform, opacity;
    color: $c-black;
    font-family: $f-family;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    margin-right: 50px;
    margin-bottom: 0;

    @include small-size-max {
      margin-right: 0;
    }
  }

  &__description {
    will-change: transform, opacity;
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
    margin-right: auto;
    margin-bottom: 0;
  }

  &__btn {
    margin: 10px 0;

    @include small-size-max {
      margin: 10px 0 0;
    }
  }
}
