@import "./setup/setup";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $f-family !important;
}

#root {
  overflow: clip;
  position: relative;
}

.container {
  max-width: 100%;
  margin: 0 15px;

  @include small-size-min {
    max-width: 540px;
    margin: 0 auto;
  }

  @include medium-size-min {
    max-width: 720px;
  }

  @include large-size-min {
    max-width: 960px;
  }

  @include extra-large-size-min {
    max-width: 1140px;
  }

  @include xx-large-size-min {
    // max-width: 1280px;
    max-width: 1320px;
  }
}

.link {
  font-family: $f-family;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: $c-green;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.dzu-dropzone {
  min-height: 15em;
  border: 2px dotted #d9d9d9;
}

.dzu-dropzone {
  border-image-outset: null !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}