@import "../assets/scss/setup/setup.scss";

.Row {
  display: flex;
  margin-bottom: 50px;

  @include medium-size-max {
    flex-direction: column;
  }
}

.link {
  font-family: $f-family;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: $c-green;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
}

.Title {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: $c-black;
  margin-bottom: 25px;
}

.Dashboard {
  &__left {
    flex: 1;
    margin-right: 50px;

    @include medium-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__right {
    width: 410px;

    @include large-size-max {
      flex: 1;
      width: 100%;
    }
  }
}

.Enroll {
  &__row {
    align-items: flex-start;
  }

  &__left {
    flex: 1;
    margin-right: 50px;

    @include medium-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__right {
    width: 298px;

    @include large-size-max {
      flex: 1;
      width: 100%;
    }
  }

  &__subtitle {
    font-family: $f-family;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: $c-black;
    margin-bottom: 20px;
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
  }
}

.EnrollDetail {
  @include medium-size-max {
    overflow: hidden;
  }

  &__left {
    flex: 1;
    margin-right: 25px;

    @include medium-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__right {
    width: 420px;

    @include large-size-max {
      flex: 1;
      width: 100%;
    }
  }

  &__title {
    margin: 15px 0 30px;
    font-family: $f-family;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    color: $c-black;
  }

  &__icon {
    color: $c-icon;

    &__info {
      width: 19px;
      height: 19px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.FileUpload {
  &__row {
    align-items: flex-start;

    @include large-size-max {
      align-items: initial;
    }
  }

  &__left {
    width: 500px;
    margin-right: 50px;

    @include large-size-max {
      flex: 1;
      width: 100%;
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__right {
    flex: 1;
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
  }
}

.Table {
  &__info {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
    color: $c-table-body;
  }

  &__action {
    text-decoration: none;
    font-weight: 900;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}

.QueryTool {
  &_left {
    flex: 30%;
    margin-right: 50px;

    @include medium-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &_right {
    flex: 70%;
    @include large-size-max {
      flex: 4;
      width: 100%;
    }
  }

  &_Saved {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &_Copy {
    height: 100%;
    display: inline;
    position: relative;
    right: 0px;
    width: 100%;

    >svg {
      position: relative;
      right: 0px;
    }
  }

  &_ButtonArea {
    display: flex;
    justify-content: space-between;
  }
}

.History {
  margin-bottom: 40px;
}

.Notes {
  background-color: $c-lime;
  padding: 15px 15px 20px;

  &__title {
    margin: 0;
  }
}
